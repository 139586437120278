import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const WattsTracker = Loadable(
  lazy(() => import("src/pages/admin/energy-vault/watts-tracker"))
);
const EnergyWallet = Loadable(
  lazy(() => import("src/pages/admin/energy-vault/energy-wallet"))
);
const ActiveEnergyPackages = Loadable(
  lazy(() => import("src/pages/admin/energy-vault/active-energy-packages"))
);

const energyVault = [
  {
    path: "energy-vault",
    children: [
      { element: <Navigate to="watts-tracker" />, index: true },
      { path: "watts-tracker", element: <WattsTracker /> },
      { path: "energy-wallet", element: <EnergyWallet /> },
      { path: "active-energy-packages", element: <ActiveEnergyPackages /> },
    ],
  },
];

export default energyVault;
