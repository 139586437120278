// theme
import palette from "../theme/palette";

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: "default",
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: "purple",
    lighter: "#EBD6FD",
    light: "#B985F4",
    main: "#7635dc",
    dark: "#431A9E",
    darker: "#200A69",
    contrastText: "#fff",
  },
  // CYAN
  {
    name: "cyan",
    lighter: "#D1FFFC",
    light: "#76F2FF",
    main: "#1CCAFF",
    dark: "#0E77B7",
    darker: "#053D7A",
    contrastText: palette.light.grey[800],
  },
  // OLD BLUE
  // {
  //   name: "blue",  
  //   lighter: "#C8FACD",
  //   light: "#5BE584",
  //   main: "#00AB55",
  //   dark: "#007B55",
  //   darker: "#005249",
  //   contrastText: "#fff",
  // },

  // NEW BLUE
  {
    name: "blue",  
    lighter: "#99E6C0",
    light: "#4DDC92",
    main: "#00BE66",
    dark: "#009350",
    darker: "#006C3A",
    contrastText: "#fff",
  },

  // //OLD ORANGE
  // {
  //   name: "orange",
  //   lighter: "#FEF4D4",
  //   light: "#FED680",
  //   main: "#fda92d",
  //   dark: "#B66816",
  //   darker: "#793908",
  //   contrastText: palette.light.grey[800],
  // },

  //NEW ORANGE
  {
    name: "orange",
    lighter: "#FFD5B3",
    light: "#FFB27A",
    main: "#FF924B",
    dark: "#CC753C",
    darker: "#99592D",
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: "red",
    lighter: "#FFE3D5",
    light: "#FFC1AC",
    main: "#FF3030",
    dark: "#B71833",
    darker: "#7A0930",
    contrastText: "#fff",
  },
];

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];

export default function getColorPresets(presetsKey) {
  return {
    purple: purplePreset,
    cyan: cyanPreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    default: defaultPreset,
  }[presetsKey];
}
