import { Box, Container, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useGetLogo from "src/components/logo/hooks/use-logo";

const LoginAuthLayout = ({ children }) => {
  const logo = useGetLogo();
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        py: "150px",
 
      }}>
      <Container
        fixed
        maxWidth="xs"
        sx={{
          height: "100%",
          // textAlign: "center",
        }}>
        <Paper variant="outlined" sx={{ padding: 1,borderRadius:0,pt:4}}>
          <Box
            sx={{
              display: "flex",
              mb: 3,
              justifyContent: "center",
            }}>
            <img style={{ width: "80px", height: "auto" }} src={logo} />
          </Box>
          {children}
        </Paper>
      </Container>
    </Box>
  );
};

export default LoginAuthLayout;
