import { styled } from "@mui/material/styles";
import GetStartedTitle from "./GetStartedTitle";
import { Typography } from "@mui/material";
import { DOMAIN_NAME } from "src/config";

const Wrapper = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  left: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  // padding: theme.spacing(3),
  padding: theme.spacing(0, 3, 0, 3),
  justifyContent: "space-between",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    // padding: theme.spacing(7, 5, 0, 7),
  },
}));

const Header = ({ login }) => (
  <Wrapper>
    <Typography variant="subtitle2" color={"#fff"} mt={1}>
      {DOMAIN_NAME}
    </Typography>
    <GetStartedTitle login={login} />
  </Wrapper>
);

export default Header;
