import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormContext } from "react-hook-form";
import { RECAPTCHA_SITE_KEY } from "src/config";
import useIsDarkMode from "src/hooks/use-is-darkmode";
import axiosInstance from "src/utils/axios";

function Recaptcha() {
  const { setValue } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();
  const recaptcha = useRef();
  const isDark = useIsDarkMode();

  const onChange = async () => {
    try {
      const captchaValue = recaptcha.current.getValue();
      if (!captchaValue) {
        recaptcha.current.reset();
        enqueueSnackbar("Please verify captcha", { variant: "error" });
      } else {
        const reqData = new FormData();
        reqData.append("captcha_value", captchaValue);
        const { status, data } = await axiosInstance.post(
          "/api/verify-recaptcha",
          reqData
        );
        if (status === 200) {
          setValue("captcha", true);
        }
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: "error" });
      recaptcha.current.reset();
    }
  };

  const onExpired = () => {
    recaptcha.current.reset();
    setValue("captcha", false);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
      <ReCAPTCHA
        theme={isDark ? "dark" : "light"}
        ref={recaptcha}
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={onChange}
        onExpired={onExpired}
        onVerify={() => console.log("verified")}
      />
    </Box>
  );
}

export default Recaptcha;
