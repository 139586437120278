import { Box, Stack, Typography } from "@mui/material";
import Translate from "src/components/translate";
import { DOMAIN_NAME } from "src/config";

const Message = () => (
  <Stack direction="row" alignItems="flex-start" sx={{ mb: 2 }}>
    {/* <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" component="h1">
        <Translate>register.welcome</Translate>
      </Typography>
      <Typography variant="subtitle2" gutterBottom component="h2">
        <Translate>register.sign_to</Translate> {DOMAIN_NAME}
      </Typography>
    </Box> */}
    <Box sx={{ flexGrow: 1}}>
      <Typography sx={{ fontSize: "25px" }}>
        <Translate>register.login_to</Translate>
      </Typography>
      <Typography sx={{ fontSize: "13px" }}>
        <Translate>register.enter_credential</Translate>
      </Typography>
    </Box>
  </Stack>
);

export default Message;
